import React, { useEffect } from "react";
import "./Unauthorized.scss";
import { logoutApp } from "../utils";

const Unauthorized = () => {
  useEffect(() => {
    if (localStorage.getItem("token")) {
      logoutApp();
    }
  }, []);

  return (
    <div className="container-wrap">
      <div className="content-wrap">
        <div className="app-logo"></div>
        <div>
          <p>Dear User,</p>
          <p>
            Oops! It seems like your access hasn't been set up or you don't have
            permission for this tool.
          </p>
          <p>
            For any inquiries or further assistance, please do not hesitate to
            reach out to our support team.
          </p>
          <p>Thank you for your cooperation.</p>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
