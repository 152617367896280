const URL = process.env.REDIRECT_URI;
export const PINGURL = `https://${process.env.REACT_APP_WEB_ENV}.roche.com/as/authorization.oauth2?response_type=code&client_id=analyticsHubParent&scope=openid+profile&redirect_uri=${URL}`;
export const IDTOKEN = "id_token";
export const QLIKCOOKIE = "qlikCookie";
export const QLIKTOKEN = "qlikToken";
export const TOKEN = "token";
export const UNIXID = "unixId";
export const USERNAME = "userName";
export const ANALYTICSHUB = "analyticshub";
export const CURRENTAPPURL = "currentApp";
export const LOGOUT = "logout";
export const REFRESHTOKEN = "refresh_token";
export const TRACKER = "__tracker";
export const CDATE = "__cDate";
