import axios from "axios";
import { IDTOKEN, TOKEN } from "./constant";
export const isTokenExpired = (token) => {
  const decodedJWT = parseJWT(token);
  if (decodedJWT !== null && decodedJWT.exp * 1000 > Date.now()) {
    return false;
  } else {
    return true;
  }
};

export const parseJWT = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const logoutApp = async () => {
  try {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem(TOKEN)}`;
    axios.defaults.headers.common["idToken"] = `${localStorage.getItem(
      IDTOKEN
    )}`;
    let response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/ahubapi/logout`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        body: {},
      }
    );
    localStorage.clear();
    return response;
  } catch (e) {
    localStorage.clear();
    console.log("something went wrong", e);
  }
};
