import { registerApplication, start } from "single-spa";
import { bootstrap, mount, unmount } from "./root.component.js";

registerApplication(
  "@roche/root-config",
  () => import("./root.component.js"),
  () => true,
  { bootstrap, mount, unmount }
);

registerApplication({
  name: "@roche/sai",
  app: () => System.import("@roche/sai"),
  activeWhen: (location) => {
    return (
      localStorage.getItem("token") &&
      location.pathname.startsWith("/analyticshub/saif")
    );
  },
});

registerApplication({
  name: "@roche/digital-insight",
  app: () => System.import("@roche/digital-insight"),
  activeWhen: (location) => {
    return (
      localStorage.getItem("token") &&
      location.pathname.startsWith("/analyticshub/digital-insight")
    );
  },
});

start();
