import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import {
  USERNAME,
  UNIXID,
  TOKEN,
  QLIKTOKEN,
  QLIKCOOKIE,
  IDTOKEN,
  ANALYTICSHUB,
  LOGOUT,
  REFRESHTOKEN,
  CDATE,
  TRACKER,
} from "./constant";
import axios from "axios";

const SESSION_IDEL_MINUTES = process.env.IDLE_SESSION_TIME_OUT || 30;

const AutoTimeOut = (props) => {
  const { ComposedClass } = props;
  const token = localStorage.getItem("token");
  const idToken = localStorage.getItem("id_token");

  const navigate = useNavigate();

  const handleOnIdle = async () => {
    // user is logged out after 30 minutes fo idle time
    if (token && idToken) {
      try {
        const Idtoken = localStorage.getItem(IDTOKEN);
        const token = localStorage.getItem(TOKEN);
        // const payload = { id };
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem(TOKEN)}`;
        axios.defaults.headers.common["idToken"] = `${localStorage.getItem(
          IDTOKEN
        )}`;

        let response = await axios.post(
          `${process.env.REACT_APP_API_HOST}/ahubapi/logout`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            body: {},
          }
        );

        // need to remove individual Items as need to keep current APP url
        localStorage.removeItem(IDTOKEN);
        localStorage.removeItem(QLIKCOOKIE);
        localStorage.removeItem(QLIKTOKEN);
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(UNIXID);
        localStorage.removeItem(USERNAME);
        localStorage.removeItem(USERNAME);
        localStorage.removeItem(REFRESHTOKEN);
        localStorage.removeItem(TRACKER);
        localStorage.removeItem(CDATE);
        navigate(`/${LOGOUT}`);
      } catch (error) {
        navigate("/logout");
        localStorage.removeItem(IDTOKEN);
        localStorage.removeItem(QLIKCOOKIE);
        localStorage.removeItem(QLIKTOKEN);
        localStorage.removeItem(TOKEN);
        localStorage.removeItem(UNIXID);
        localStorage.removeItem(USERNAME);
        localStorage.removeItem(USERNAME);
        localStorage.removeItem(REFRESHTOKEN);
        localStorage.removeItem(TRACKER);
        localStorage.removeItem(CDATE);
        navigate(`/${LOGOUT}`);
      }
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * SESSION_IDEL_MINUTES, // set to 30 minutes
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return <ComposedClass />;
};

export default AutoTimeOut;
