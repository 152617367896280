// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/icon_no-data.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-wrap{display:flex;justify-content:center}.content-wrap{display:flex;flex-direction:column}.app-logo{height:30vh;width:10vw;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-size:cover;background-repeat:no-repeat;display:flex;align-self:center}`, "",{"version":3,"sources":["webpack://./src/components/authenticaton/unauthorized/Unauthorized.scss"],"names":[],"mappings":"AAAA,gBACE,YAAA,CACA,sBAAA,CAGF,cACE,YAAA,CACA,qBAAA,CAGF,UACE,WAAA,CACA,UAAA,CACA,wDAAA,CACA,qBAAA,CACA,2BAAA,CACA,YAAA,CACA,iBAAA","sourcesContent":[".container-wrap {\n  display: flex;\n  justify-content: center;\n}\n\n.content-wrap {\n  display: flex;\n  flex-direction: column;\n}\n\n.app-logo {\n  height: 30vh;\n  width: 10vw;\n  background-image: url(\"../../../assets/icon_no-data.png\");\n  background-size: cover;\n  background-repeat: no-repeat;\n  display: flex;\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
