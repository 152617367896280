import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getAuthenticated,
  validateToken,
  getUserAuthorization,
} from "./../../../server/authenticate.service";
import { isTokenExpired } from "./utils";
import PuffLoader from "react-spinners/PuffLoader";
import { CURRENTAPPURL } from "./constant";

const AuthenticateUser = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const codeFromParams = searchParams.get("code");
  const codeFromParamsError = searchParams.get("error");
  const navigate = useNavigate();

  useEffect(() => {
    //
    if (
      (!localStorage.getItem("token") ||
        isTokenExpired(localStorage.getItem("token"))) &&
      !codeFromParamsError
    ) {
      // calls login API to fetch token when not present
      login();
    } else {
      // checks for the user access in case of URL change while being authenticated
      if (!codeFromParamsError) {
        const id = localStorage.getItem("unixId");
        authorizeUser(id);
      }
    }
  }, [codeFromParams || window.location.href, codeFromParamsError]);

  const authorizeUser = async (unixId) => {
    const data = await getUserAuthorization(unixId);
    // console.log("data: ", data);
    const currentURL = localStorage.getItem(CURRENTAPPURL);

    // console.log("currentURL: ", currentURL)
    // &&
    // item?.ApplicationName === currentURL?.toUpperCase() need to fix it
    // console.log("data: ", data, currentURL)
    const isAuthorized = data?.filter(
      (item) =>
        item?.UnixId?.toLowerCase() === unixId.toLowerCase() &&
        item?.ApplicationName?.toUpperCase() === currentURL?.toUpperCase()
    );
    // console.log("isAuthorizedLength: ", isAuthorized)
    if (isAuthorized?.length) {
      // navigate(`/${currentURL}`); need to update hard code change logic for now doing to update SSO working
      navigate(`/${currentURL}`);
    } else {
      navigate("/unauthorized");
    }
  };

  useEffect(() => {
    if (codeFromParamsError) {
      navigate("/unauthorized");
      // return "";
    }
  }, [codeFromParamsError]);

  const isTokenValid = async (token) => {
    //API handler to get the unixId of logged in user
    const response = await validateToken(token);
    if (response?.access_token) {
      await authorizeUser(response?.access_token?.unixId);
      const username =
        response?.access_token?.["first name"] +
        " " +
        response?.access_token?.["last name"];
      localStorage.setItem("unixId", response?.access_token?.unixId);
      localStorage.setItem("userName", username);
      // storing unixid in local storage to pass to authorize endpoint
    } else {
      navigate("/");
    }
  };

  const login = async () => {
    //API handler called to fetch access token
    setLoading(true);
    const payload = {
      access_code: codeFromParams,
      redirect_uri: process.env.REDIRECT_URI,
    };
    const response = await getAuthenticated(payload);
    // console.log("updated: ", response);
    const tokenIsUndefined = response?.access_token === undefined;
    if (!tokenIsUndefined) {
      //storing the parameters received
      localStorage.setItem("token", response?.access_token);
      localStorage.setItem("refresh_token", response?.refresh_token);
      localStorage.setItem("id_token", response?.id_token);
      //calling validate endpoint to check token validity
      isTokenValid(response?.access_token);
    }
    setLoading(false);
  };

  return (
    //TODO: Center the loader.
    <div
      className="spinner center-page animate__animated animate__fadeIn"
      style={{ position: "relative", top: "50%", left: "50%" }}
    >
      <PuffLoader color="#004677" size={60} loading={loading} />
    </div>
  );
};

export default AuthenticateUser;
