import axiosDBClient from "./config/axiosDBClient";

export const getAuthenticated = async (payload) => {
  const result = await axiosDBClient.post("/authenticate", {
    payload,
  });

  if (result.error) {
    throw new Error("Could not authenticate!");
  }
  return result.data;
};

export const validateToken = async (token) => {
  const result = await axiosDBClient.post("/validate", {
    token,
  });

  if (result.error) {
    throw new Error("Could not validate token!");
  }
  return result.data;
};

export const getUserAuthorization = async (unixId) => {
  const result = await axiosDBClient.get("/authorize", {
    params: { unixId },
  });

  if (result.error) {
    throw new Error("Could not fetch data!");
  }
  return result.data;
};

export const refreshToken = async (rtoken) => {
  const result = await axiosDBClient.post("/refreshtoken", { rtoken });

  if (result.error) {
    throw new Error("Could not refresh token!");
  }
  return result.data;
};
