/* eslint-disable prettier/prettier */
import React, { useEffect } from "react";
import { PINGURL } from "./constant";
import { isTokenExpired } from "./utils";

const AuthenticationMain = () => {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (isTokenExpired(token)) {
      // redirects to the authentication endpoint of ping in case of token absence
      window.location.href = PINGURL;
    }
    // else condition if token is not expired already handled in Root file thus no need to add useEffect here
  }, []);
};

export default AuthenticationMain;
