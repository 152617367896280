import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  getUserAuthorization,
  refreshToken,
} from "../server/authenticate.service";
import AuthenticateUser from "./components/authenticaton/AuthenticateUser";
import AuthenticationMain from "./components/authenticaton/AuthenticationMain";
import LogoutModal from "./components/authenticaton/logoutmodal/LogoutModal";
import Unauthorized from "./components/authenticaton/unauthorized/Unauthorized";
import NotFound from "./components/authenticaton/NotFound/NotFound";

const sanctionedURL = process.env.REACT_APP_PATHNAME?.split(",");
export default function MyRoot() {
  const { pathname } = useLocation();
  const SESSION_REFRESH_TIME = process.env.SESSION_REFRESH_MINUTES || 15;
  const navigate = useNavigate();
  const [currentURL, setCurrentURL] = useState();

  useEffect(() => {
    let urls = new URL(window.location.href);

    if (
      sanctionedURL?.includes(urls?.pathname?.split("/")[2]) &&
      urls?.pathname?.split("/")?.length > 3
    ) {
      urls.pathname = urls?.pathname?.split("/")?.slice(0, 3).join("/");
    }
    if (
      urls &&
      urls?.pathname?.split("/")?.length <= 3 &&
      sanctionedURL?.includes(urls?.pathname?.split("/")[2])
    ) {
      setCurrentURL(urls);
    }
  }, []);

  useEffect(() => {
    if (currentURL) {
      if (currentURL && currentURL?.pathname?.split("/")?.length <= 3) {
        //called when a change in URl is detected or in absence of token

        const token = localStorage.getItem("token");
        if (
          !token ||
          currentURL.pathname?.split("/")[2] !==
            localStorage.getItem("currentApp")
        ) {
          if (
            currentURL &&
            sanctionedURL?.includes(currentURL?.pathname?.split("/")[2])
          ) {
            localStorage.setItem(
              "currentApp",
              currentURL?.pathname?.split("/")[2]
            );

            authorizeUser(token);
            // to avoid going to default validation
            return "";
          }
        }
        //default validation.
        authorizeUser(token, true);
      }
    }
  }, [currentURL]);

  useEffect(() => {
    // called every 15 mins to refresh the access token and reset it again
    const interval = setInterval(() => {
      refreshAccessToken();
    }, 1000 * 60 * SESSION_REFRESH_TIME);
    return () => clearInterval(interval);
  }, []);

  const refreshAccessToken = async () => {
    //API handler for refresh token
    const rtoken = localStorage.getItem("refresh_token");
    if (rtoken) {
      const response = await refreshToken(rtoken);
      if (response?.refresh_token) {
        localStorage.setItem("token", response?.access_token);
        localStorage.setItem("refresh_token", response?.refresh_token);
        // localStorage.setItem("id_token", response?.id_token);// since Id token is not coming in response
      }
    }
  };

  const authorizeUser = async (token, loggedInUser = false) => {
    //API handler for authorizing user access to the sub-apps
    const id = localStorage.getItem("unixId");
    const currentURL = localStorage.getItem("currentApp");

    if (token) {
      const response = await getUserAuthorization(id);
      const isAuthorized = response?.filter(
        (item) =>
          item?.UnixId?.toLowerCase() === id.toLowerCase() &&
          item?.ApplicationName?.toUpperCase() === currentURL?.toUpperCase()
      );
      // console.log("isAuthorized check", isAuthorized, response);
      if (isAuthorized?.length && !loggedInUser) {
        navigate(`/${currentURL}`);
      } else if (isAuthorized?.length && loggedInUser) {
        // if user is logged in and trying to refresh the page or navigate
        navigate(pathname);
      } else {
        navigate("/unauthorized");
      }
    } else {
      navigate("/");
    }
  };

  return (
    <Routes>
      <Route path="/" element={<AuthenticationMain />} />
      <Route path="/saif" element={<AuthenticationMain />} />
      <Route path="/saif/*" element={<AuthenticationMain />} />
      <Route path="/digital-insight" element={<AuthenticationMain />} />
      <Route path="/digital-insight/*" element={<AuthenticationMain />} />
      <Route path="/authenticate" element={<AuthenticateUser />} />
      <Route path="/logout" element={<LogoutModal />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
