import React from "react";
import {
  ANALYTICSHUB,
  CURRENTAPPURL,
  PINGURL,
  TOKEN,
  IDTOKEN,
  QLIKCOOKIE,
  QLIKTOKEN,
  UNIXID,
  USERNAME,
  REFRESHTOKEN,
  TRACKER,
  CDATE,
} from "../constant";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const LogoutModal = () => {
  const navigate = useNavigate();
  const navigateToHome = () => {
    // TODO: Create a method to delete selected Storages
    localStorage.removeItem(TOKEN);

    localStorage.removeItem(IDTOKEN);
    localStorage.removeItem(QLIKCOOKIE);
    localStorage.removeItem(QLIKTOKEN);
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(UNIXID);
    localStorage.removeItem(USERNAME);
    localStorage.removeItem(REFRESHTOKEN);
    localStorage.removeItem(TRACKER);
    localStorage.removeItem(CDATE);
    let currentAPPURL = localStorage.getItem(CURRENTAPPURL);

    let url = PINGURL;
    window.location.href = url;
  };
  return (
    <div className="container-wrap">
      <div className="content-wrap">
        <div className="app-logo"></div>
        <div>
          <p>You have been logged out.</p>
          <p>
            Your session has expired. Click "Log in" to redirect to the login
            page.
          </p>
          <Button onClick={navigateToHome}>
            <span>Log in</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
