import React from "react";
import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="notFoundPageContainer">
      <h1>404 Error</h1>
      <p>Page Not Found</p>
    </div>
  );
};

export default NotFound;
