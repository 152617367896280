import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import singleSpaReact from "single-spa-react";
import AutoTimeOut from "./components/authenticaton/AutoTimeOut";
import MyRoot from "./root";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: (props) => (
    <Router basename="/analyticshub">
      <AutoTimeOut ComposedClass={MyRoot} />
    </Router>
  ),
});
export const { bootstrap, mount, unmount } = reactLifecycles;
